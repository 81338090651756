import { Doughnut } from 'react-chartjs-2'
import { Props } from './types'
import { FactorData } from '../../common'

export const DoughnutChart: React.FC<Props> = ({ data }) => {
  return (
    <div className='flex h-full w-full flex-col rounded-3xl bg-white p-6'>
      <span className='text-2xl font-semibold text-black'>Visão geral</span>

      <div className='flex h-full min-w-full items-center justify-start gap-6'>
        <div className='mr-6 flex flex-col gap-4'>
          {(Object.keys(data) as Array<keyof typeof data>).map(key => {
            const FactorProps = FactorData[key]

            return (
              <div className='flex flex-col' key={key.toLowerCase()}>
                <span
                  className={`text-sm font-semibold `}
                  style={{ color: FactorProps.primary_color }}
                >
                  {FactorProps.title}
                </span>
                <span className='text-[28px] font-bold text-black'>{data[key]}</span>
              </div>
            )
          })}
        </div>
        <div className='h-96 w-96'>
          <Doughnut
            data={{
              labels: (Object.keys(data) as Array<keyof typeof data>).map(
                key => FactorData[key].title
              ),
              datasets: [
                {
                  label: 'Dataset',
                  data: (Object.keys(data) as Array<keyof typeof data>).map(key => data[key]),
                  backgroundColor: (Object.keys(data) as Array<keyof typeof data>).map(
                    key => FactorData[key].primary_color
                  ),
                  hoverOffset: 4
                }
              ]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DoughnutChart
