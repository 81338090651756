import { useState } from 'react'
import { Props } from './types'
import { Icon } from 'semente-js'

export const ImpactChart: React.FC<Props> = ({ impacts }) => {
  const [currentImpact, setCurrentImpact] = useState(0)
  const ImpactData = impacts[currentImpact]

  return (
    <>
      <div className='flex h-full w-96 flex-col border-r-[1px] border-r-neutral-300 p-6'>
        <span className='text-2xl font-semibold text-black'>Impactos</span>
        <span className='text-base font-normal text-black'>
          Visualize os impactos de cada etapa da cadeia de valor
        </span>

        <span className='mt-6 text-xl font-semibold text-black'>Etapas</span>

        {impacts.map((impact, index) => (
          <button
            className={`flex w-full flex-col ${impacts.length - 1 !== index && 'border-b-[1px] border-b-gray-200'}  py-4 text-start`}
            onClick={() => setCurrentImpact(index)}
            key={index}
          >
            <span className='text-base font-semibold text-black'>{impact.title}</span>
            <span className='text-sm font-normal text-neutral-500'>
              {impact.indicadores.length} Impactos
            </span>
            <span className='text-sm font-normal text-neutral-500'>
              {impact.indicador_de_negocio?.area.length} Impactos
            </span>
          </button>
        ))}
      </div>

      <div className='flex w-full flex-col p-6'>
        <span className='text-2xl font-semibold text-black'>{ImpactData.title}</span>

        <div className='mt-4 flex w-full items-start gap-2 border-b-[1px] border-b-gray-200 pb-6'>
          <Icon name='users-alt' size='16' />

          <div className='flex flex-col'>
            <span className='text-sm font-semibold'>Áreas da empresa envolvidas</span>
            <div className='mt-2 flex gap-2'>
              {ImpactData.indicador_de_negocio?.area.map(area => (
                <div className='bg- rounded-full bg-neutral-100 px-2 py-1' key={area.toLowerCase()}>
                  <span className='text-sm font-semibold text-black'>{area}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='pt-6'>
          <span className='text-xl font-semibold text-black'>Impactos</span>

          <div className='mt-2 flex w-full justify-between pb-6'>
            <input
              placeholder='Procurar'
              className='w-80 rounded-lg border-[1px] border-gray-200 px-4 py-2'
            />

            <button className='flex items-center gap-2 rounded-lg bg-gray-100 px-3 py-[10px]'>
              <Icon name='filter' size='18' />
              <span>Filtrar</span>
            </button>
          </div>

          <div className='flex w-full items-center gap-4 rounded-xl border-[1px] border-gray-100 p-4'>
            <Icon name={'thumbs-up'} size='24' color='#33CC52' />

            <div className='flex flex-col'>
              <span className='tet-black text-base font-semibold'>Nome do impacto</span>
              <span className='text-sm font-normal text-neutral-500'>
                Recursos Humanos, Financeiro
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImpactChart
