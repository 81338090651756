import { createBrowserRouter } from 'react-router-dom'
import HomePage from '../pages/home'
import { ROUTES } from './path'

export const router = createBrowserRouter([
  {
    path: ROUTES.home,
    children: [{ index: true, element: <HomePage /> }]
  }
])
