import { IconNameType } from 'semente-js'

export const FactorData = {
  E: {
    primary_color: '#33CC52',
    secondary_color: '#E0F7E5',
    title: 'Ambiental',
    icon: 'globe' as IconNameType
  },
  S: {
    primary_color: '#FF9900',
    secondary_color: '#FFF0D9',
    title: 'Social',
    icon: 'users-alt' as IconNameType
  },
  G: {
    primary_color: '#B961E5',
    secondary_color: '',
    title: 'Governança',
    icon: 'building' as IconNameType
  }
}
