import React from 'react'
import { Props } from './types'
import { Icon } from 'semente-js'
import { FactorData } from '../../common'

const InverseBar: React.FC<Props> = ({ data }) => {
  return (
    <div className='h-full w-full rounded-3xl bg-white p-6'>
      <span className='text-2xl font-semibold text-black'>Top temas materiais</span>

      {(Object.keys(data) as Array<keyof typeof data>).map((key, index) => {
        const FactorProps = FactorData[key]

        return (
          <div
            className={`flex w-full flex-col items-start ${index !== Object.keys(data).length - 1 && 'border-b-[1px] border-b-gray-100'} py-6`}
            key={key.toString()}
          >
            <div className='mb-1 flex items-center gap-2'>
              <Icon name={FactorProps.icon} color={FactorProps.primary_color} size='18' />
              <span className='text-base font-semibold text-black'>{FactorProps.title}</span>
            </div>

            {data[key].map(category => (
              <div
                key={category.title.toLowerCase()}
                className='flex w-full items-center justify-end gap-4'
              >
                <span className='text-sm text-gray-400'>{category.title}</span>
                <div className='h-2 w-[300px] rounded-sm bg-transparent'>
                  <div
                    className='h-full w-2/4 rounded-sm'
                    style={{ background: FactorProps.primary_color }}
                  />
                </div>
                <span className='text-sm text-gray-400'>20</span>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default InverseBar
