import { Props } from '../types'

export const InverseTransformer = (): Props['data'] => {
  return {
    E: [
      {
        title: 'Um tema muito grande ficaria assim',
        count: 20
      },
      {
        title: 'Um teminha',
        count: 10
      },
      {
        title: 'Tema de tamanho OK',
        count: 5
      }
    ],
    S: [
      {
        title: 'Um tema muito grande ficaria assim',
        count: 20
      },
      {
        title: 'Um teminha',
        count: 10
      },
      {
        title: 'Tema de tamanho OK',
        count: 5
      }
    ],
    G: [
      {
        title: 'Um tema muito grande ficaria assim',
        count: 20
      },
      {
        title: 'Um teminha',
        count: 10
      },
      {
        title: 'Tema de tamanho OK',
        count: 5
      }
    ]
  }
}
