import DoughnutChart from '../../components/doughnut-chart'
import { Icon } from 'semente-js'
import 'chart.js/auto'
import { DoughnutTransformer } from '../../components/doughnut-chart/transformer'
import InverseBar from '../../components/inverse-bar'
import { InverseTransformer } from '../../components/inverse-bar/transformer'
import BubbleChart from '../../components/bubble'
import { BubbleTransformer } from '../../components/bubble/transformer'
import ImpactChart from '../../components/impact'
import { ImpactTransformer } from '../../components/impact/transformer'

const HomePage = () => {
  return (
    <main className='flex h-screen w-full items-center justify-center overflow-auto px-16'>
      <div className='flex h-full w-full max-w-[1310px] flex-col pt-5'>
        <div className='flex w-full items-center justify-between'>
          <span className='text-2xl font-semibold'>Smart ESG</span>

          <div className='flex items-center rounded-2xl bg-white px-3 py-4 text-base font-semibold text-[#FF4791]'>
            <Icon name='sparkle' color='#FF4791' size='24' />
            <span className='ml-2 text-base font-semibold'>
              Resultados gerados usando a IA Smart ESG™
            </span>
          </div>

          <div className='flex items-center rounded-2xl bg-white p-2 font-semibold text-[#FF4791]'>
            <div className='flex h-9 w-9 items-center justify-center rounded-lg'>
              <Icon name='grid-layout' color='#000000' size='16' />
            </div>

            <div className='flex h-9 w-9 items-center justify-center rounded-lg bg-black'>
              <span className='text-base font-semibold text-white'>A</span>
            </div>
          </div>
        </div>

        <div className='mt-16 flex w-full justify-between gap-9'>
          <DoughnutChart data={DoughnutTransformer()} />

          <InverseBar data={InverseTransformer()} />
        </div>

        <div className='mt-16  w-full rounded-3xl bg-white p-6'>
          <BubbleChart data={BubbleTransformer()} />
        </div>

        <div className='mt-16 pb-5 '>
          <div className='flex  w-full rounded-3xl bg-white'>
            <ImpactChart impacts={ImpactTransformer()} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default HomePage
