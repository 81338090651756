import { Props } from '../types'

export const ImpactTransformer = (): Props['impacts'] => {
  return mockData
}

const mockData: Props['impacts'] = [
  {
    title: 'Uso da tecnologia para reciclagem de água',
    probabilidade: 4,
    severidade: 3,
    tipo: 'positivo',
    peso: 't',
    stakeholder: ['Empresa X', 'ONG Y'],
    indicador_de_negocio: {
      area: ['Operacional', 'Sustentabilidade'],
      title: 'Redução de custo operacional',
      description:
        'A utilização de tecnologias de reciclagem de água diminui os gastos com recursos hídricos.'
    },
    indicadores: [
      {
        tema: {
          title: 'Água e Efluentes',
          fator: 'E'
        },
        title: 'Eficiencia no uso da água',
        requisitos: [
          'Descrição de como a organização interage com a água.',
          'Metas relacionadas à conservação de água.',
          'Engajamento com stakeholders na gestão hídrica.',
          'Gestão do impacto ambiental relacionado ao uso da água.'
        ]
      }
    ]
  },
  {
    title: 'Automação na gestão de resíduos',
    probabilidade: 5,
    severidade: 4,
    tipo: 'positivo',
    peso: 'r',
    stakeholder: ['Empresa Z', 'Governo Local'],
    indicador_de_negocio: {
      area: ['Logística', 'Sustentabilidade'],
      title: 'Aumento na eficiência de gestão de resíduos',
      description:
        'Automatizar a coleta e reciclagem de resíduos reduz o desperdício e melhora a eficiência do processo.'
    },
    indicadores: [
      {
        tema: {
          title: 'Resíduos Sólidos',
          fator: 'S'
        },
        title: 'Eficiência na coleta de resíduos',
        requisitos: [
          'Processos para reduzir resíduos sólidos gerados.',
          'Iniciativas para reaproveitamento e reciclagem de materiais.',
          'Planos para redução de resíduos perigosos.',
          'Engajamento de fornecedores na gestão sustentável de resíduos.'
        ]
      }
    ]
  },
  {
    title: 'Energia renovável na produção',
    probabilidade: 3,
    severidade: 2,
    tipo: 'positivo',
    peso: 'v',
    stakeholder: ['Empresa A', 'Comunidade B'],
    indicador_de_negocio: {
      area: ['Energia', 'Inovação'],
      title: 'Implementação de fontes de energia renovável',
      description:
        'A produção com energia renovável reduz o impacto ambiental e melhora a eficiência energética.'
    },
    indicadores: [
      {
        tema: {
          title: 'Energia e Emissões',
          fator: 'G'
        },
        title: 'Uso de fontes renováveis de energia',
        requisitos: [
          'Adoção de fontes limpas de energia.',
          'Redução de emissões de gases poluentes.',
          'Relatórios de impacto ambiental sobre o uso de energia.',
          'Metas claras para uso sustentável de energia.'
        ]
      }
    ]
  }
]
