import { Props } from '../types'

export const BubbleTransformer = (): Props['data'] => {
  return [
    { category: 'Biodiversidade', factor: 'S', r: 20, x: 5, y: 3 },
    { category: 'Categoria 2', factor: 'S', r: 20, x: 3, y: 4 },
    { category: 'Categoria 3', factor: 'E', r: 20, x: 7, y: 6 },
    { category: 'Categoria 4', factor: 'G', r: 20, x: 9, y: 10 }
  ]
}
