import { Bubble } from 'react-chartjs-2'
import { Props } from './types'
import { Icon } from 'semente-js'
import { FactorData } from '../../common'

export const BubbleChart: React.FC<Props> = ({ data }) => {
  return (
    <div className='flex h-full w-full flex-col'>
      <span className='text-2xl font-semibold text-black'>Matriz de materialidade</span>

      <div className='mt-8 flex w-full gap-9'>
        <div className='w-2/4'>
          <Bubble
            data={{
              datasets: [
                {
                  label: 'First Dataset',
                  data: [
                    {
                      x: 20,
                      y: 30,
                      r: 15
                    },
                    {
                      x: 40,
                      y: 10,
                      r: 10
                    }
                  ],
                  backgroundColor: 'rgb(255, 99, 132)'
                }
              ]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false
                }
              }
            }}
          />
        </div>
        <div className='flex w-2/4 flex-col items-start'>
          <span className='text-xl font-semibold text-black'>Temas prioritários</span>
          <span className='mt-2 text-sm font-normal text-black'>
            Os três temas mais relevantes da Matriz de Materialidade, com alta prioridade para a
            organização e stakeholders.
          </span>

          {data.slice(0, 3).map((data, index) => {
            const FactorProps = FactorData[data.factor]

            return (
              <div key={data.category.toLowerCase()} className='flex w-full items-center gap-4'>
                <div
                  className='flex h-[36px] w-[36px] items-center justify-center rounded-full'
                  style={{ background: FactorProps.secondary_color }}
                >
                  <Icon name={FactorProps.icon} size='18' color={FactorProps.primary_color} />
                </div>

                <div
                  className={`flex flex-1 py-4 ${index !== 2 && 'border-b-[1px] border-b-gray-100'}`}
                >
                  <div className='flex w-full flex-col'>
                    <span className='text-base font-semibold text-black'>{data.category}</span>
                    <span
                      className='text-base font-semibold'
                      style={{ color: FactorProps.primary_color }}
                    >
                      {FactorProps.title}
                    </span>
                  </div>

                  <span className='text-base font-semibold text-black'>{data.x}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BubbleChart
